.btnStore {
  background: #88e3d5 !important;
  color: #f5f9f9 !important;
  font-weight: bold !important;
}

.btnStore:hover {
  background: #7bc8bb !important;
}

.btnDelete {
  background: #ee8080 !important;
  color: #ffffff !important;
}

.btnDelete:hover {
  background: #dc5f5f !important;
}

.btnEdit {
  background: #f3f375 !important;
  color: #565858 !important;
}

.btnEdit:hover {
  background: #dddd60 !important;
}

.btnInfor {
  background: #75c5f3 !important;
  /* color: #565858 !important; */
}

.btnInfor:hover {
  background: #5a9ec5 !important;
}

.btn-link {
  /* color: var(--color-primary) !important; */
  color: #89e2d6 !important;
}

.btnNav {
  background: #88e3d5 !important;
  color: #f5f9f9 !important;
  font-weight: bold !important;
}

.btnNav:hover {
  background: #7bc8bb !important;
}

.btnNav:not(.selected) {
  background: #ffffff !important;
  color: #88e3d5 !important;
  border: 1px solid #88e3d5; /* Opcional: agrega un borde si lo prefieres */
}